import {Col, Container } from "react-bootstrap";
// import Layout from "./Layout";
// import bg from "../src/assets/mechanicsbg.jpg";
import imgHeader from "../src/assets/gtec-header.jpg";
import pilotlogo from "../src/assets/pilot.png"
import gteclogo from "../src/assets/gteclogo.png"
import imglike from '../src/assets/like-follow-share.gif';
import imgfront from '../src/assets/gtec-front.gif';
import {Row} from "react-bootstrap";
import React, { forwardRef } from 'react';
import { useSwipeable } from 'react-swipeable';

const ThankYou = forwardRef(({ onSwipedDown }, ref) => {
  const handlers = useSwipeable({
    onSwipedDown: onSwipedDown,
  });
  
// function ThankYou() {
  return (  
    
    <section id="thankyou" ref={ref} {...handlers}>
      {/* backgroundImage: `url(${bg})`, */}
      <Container fluid className="mechanics-bg" style={{background:"white"}}>
        <Row>

        <img alt = '' id="imgheader" src={imgHeader} style={{width:"100%"}}></img>
          <div className="col-xs-1 col-sm-1 col-md-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-8 center"  style={{borderRadius:"10px", background:"white", marginBottom:"50px"}}>
				<div style={{alignContent:"center", alignItems:"center", backgroundColor:"white"}}>                   
                <br></br>
                <h2 style={{textAlign:"center", color:"#0050a5"}}>THANK YOU FOR JOINING</h2>
                <h2 style={{textAlign:"center", color:"#0050a5"}}>G-TEC TO JAPAN RAFFLE PROMO!</h2>
                <br></br>
                <h2 style={{textAlign:"center", color:"#e44d52"}}>Submission of raffle entry is now closed.</h2>
                <br></br><br></br>
                <h2 style={{textAlign:"center", color:"#0050a5"}}>Watch out for the announcement of our winners on September 8, 2024!</h2>
                <h2 style={{textAlign:"center", color:"#0050a5"}}>Winners will be announced on our website, Facebook and Instagram pages.</h2>
            </div>
          </div>
          {/* <img src={imglike} alt = ''></img> */}
          
</Row>

<div>
       </div>
</Container>
</section>
    // </Layout>)
    );
    
});

export default ThankYou;