import React from 'react';
import { useRef } from 'react';
import { useSwipeable } from 'react-swipeable';

// import App from './App';
import SPFront from './SPFront';
import Mechanics from './Mechanics';
import Register from './Register';
import SwipeableViews from 'react-swipeable-views';
import ThankYou from './ThankYou';

function SinglePage() {
  const thankyouRef = useRef(null);
  const spFrontRef = useRef(null);
  const registerRef = useRef(null);
  const mechanicsRef = useRef(null);

  const handleSwipedDown = (sectionRef) => {    
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleSwipedLeft = (sectionRef) => {    
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (  
    
    <>
    <ThankYou ref={thankyouRef}></ThankYou>
      {/* <SPFront ref={spFrontRef} onSwipedLeft={() => handleSwipedLeft(registerRef)}></SPFront> */}
      {/* <Register ref={registerRef} onSwipedDown={() => handleSwipedDown(spFrontRef)}></Register> */}
      <Mechanics ref={mechanicsRef}></Mechanics>
    
    </>
    
    );
}

export default SinglePage;