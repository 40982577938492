import {Container } from "react-bootstrap";
// import Layout from "./Layout";
// import bg from "../src/assets/mechanicsbg.jpg";
import pilotlogo from "../src/assets/pilot.png"
import gteclogo from "../src/assets/gteclogo.png"
import {Row} from "react-bootstrap";
import React, { forwardRef } from 'react';
import { useSwipeable } from 'react-swipeable';

const Mechanics = forwardRef(({ onSwipedDown }, ref) => {
  const handlers = useSwipeable({
    onSwipedDown: onSwipedDown,
  });
  
// function Mechanics() {
  return (  
    
    <section id="mechanics" ref={ref} {...handlers}>
      {/* backgroundImage: `url(${bg})`, */}
      <Container fluid className="mechanics-bg" style={{background:"white" , backgroundImage:`url(/assets/86-97.jpg)`, backgroundRepeat: 'repeat', paddingTop:"50px"}}>
        <Row>
          <div className="col-xs-1 col-sm-1 col-md-2"></div>
          <div className="col-xs-10 col-sm-10 col-md-8 center"  style={{borderRadius:"10px", background:"white", marginBottom:"50px"}}>
            <img alt="Pilot Logo" src={pilotlogo} style={{height:"30px", width:"auto", marginBottom:"10px", marginTop:"10px"}}></img><br></br>
            {/* <Row> */}
            <div style={{alignItems:"center"}}>
              <img alt="GTEC" src={gteclogo} style={{maxWidth:"180px", verticalAlign:"text-bottom" }}></img>
              <span style={{fontSize:"50px"}}> to Japan with Pilot G-Tec Raffle Promo</span>
              </div>
            {/* </Row>       */}

            <h3>Full mechanics and Terms and Conditions of the RAFFLE Contest</h3><br></br>
            <h3>How to Join</h3>
            <ol>
              <li>Open to all customers with a minimum single receipt purchase worth P200 of Pilot writing products
                and <b>MUST include at least one (1) Pilot G-Tec pen variant.</b></li>
              <li>Register through <a style ={{color:"blue"}} href="https://gtectoJapan.pilotpens.com.ph">https://gtectojapan.pilotpens.com.ph</a> to join and fill up all the
                necessary information needed:</li>
                <ol type="i">
                  <li>First Name</li>
                  <li>Last Name</li>
                  <li>Birthday</li>
                  <li>Mailing Address</li>
                  <li>Email Address</li>
                  <li>Mobile Number</li>
                  <li>Participating store where products are bought. (Choose from dropdown)</li>
                  {/* <li>Date of Purchase</li> */}
                  <li>Transaction Number</li>

                </ol>
              <li>Upload photo of receipt as proof of purchase.</li>
              <li>Raffle entrant can submit multiple entries. 1 receipt equals 1 entry.<br></br>
                  Each receipt must have P200 worth of Pilot writing products and must 
                  include PILOT G-Tec variant.</li>
              <li>Each entry must reflect a unique transaction receipt. Raffle entries with 
                  duplicate receipts will be disqualified. Entries submitted containing duplicate receipts will be investigated If fraud is evident, said
                  raffle entrant shall be disqualified and banned.</li>
              <li>Upon verification of entries, successful entrant will receive notification email with all the raffle details.</li>
            </ol>
            <h3>Prizes at stake:</h3>
            <h4>Grand Prize:</h4>
            <ul>
              <li>National Book Store entries: One (1) winner of trip for two to Tokyo, Japan (Round-trip plane tickets
                Economy fare with baggage allowance of 20kg, pocket money worth Php30,000 pesos and hotel
                accommodations for 2 persons for 4 days and 3 nights.)</li>
              <li>SM Stationery and Pandayan Bookshop entries: One (1) winner of trip for two to Tokyo, Japan (Roundtrip plane tickets Economy fare with baggage allowance of 20kg, pocket money worth Php30,000 pesos
                and hotel accommodations for 2 persons for 4 days and 3 nights.)</li><br></br>
                <b>Grand Prize Notes:</b>
            <ul>
              <li>*Prizes are <b>transferable</b> but <b>not convertible</b> to cash and cannot be replaced with other items or services.
                Once ticketed, it cannot be rebooked.</li>
              {/* <li>*Prizes are <b>non-transferable</b> and <b>non-convertible</b> and cannot be replaced with other items or services.
                Once ticketed, it cannot be rebooked.</li> */}
              <li>**The winners of the Japan travel package must coordinate with Cosmos Bazar, Inc. by October 31, 2024
                and provide their travel documents and target date of travel.</li>
              <li><b>*Processing of valid travel documents and necessary visa is not included in the prize.</b> Travel must be
                completed by May 31, 2025. Black-out dates are from December 16, 2024 to January 6. 2025</li>
            </ul>
            </ul>
            
            <h4>Minor Prizes:</h4>
            <ul>
              <li>10 winners of an overnight staycation at EDSA Shangri-la Hotel (1 day only) for NCR winners. Or an
                overnight hotel accommodation (1 day only) in a Philippine based resort or hotel worth Php10,000
                pesos for provincial winners.

                </li>
                <br></br>
                Any transportation and incidentals are not covered by the prize.<br></br><br></br>
                Once booked, the winner cannot ask for rebooking.<br></br>
                (Transportation and pocket money is EXCLUDED) for all participating stores. (National Book Store, SM
                Stationery and Pandayan Bookshop).<br></br><br></br>
                ** Black-out dates for the hotel staycation prize package applies for
                December 24, 2024 to January 2, 2025.<br></br><br></br>
            </ul>
            
            <h4>Consolation prizes:</h4>
            <ul>    
              <li>
                20 winners of G-Tec sets for all Participating Stores Entries: National Book Store,
                SM Stationery and Pandayan Bookshop.
              </li><br></br>
              Minor Prizes and Consolation prizes are transferable but not convertible to cash and cannot be replaced
              with other items or services.
              <br></br><br></br>
              Promo runs from <b>June 20, 2024 to August 30, 2024</b>. Entries will be accepted from
              <b>June 20, 2024 (12:01 am) to August 30, 2024 (11:59 pm)</b>.<br></br>
              Drawing of entries is on September 6, 2024.
            </ul>        
              <h3>PROCEDURE IN THE SELECTION, VERIFICATION OF WINNERS, ANNOUNCEMENT OF WINNERS &amp; CLAIMING OF PRIZES.</h3>

              <ol type="1">
                <li>Winners will be drawn in the presence of an authorized DTI representative.</li>
                <li>Winners will be notified through registered mail, text message and phone call.</li>
                <li>Winners will be announced on Pilot Pens Philippines official <a href="https://www.facebook.com/pilotpensphilippines">Facebook</a> Instagram, Online Store: <a href="https://shop.cosmos-bazar.com"> shop.cosmos-bazar.com</a>,
                  and <a href="https://www.cosmos-bazar.com">www.cosmos-bazar.com</a> website on September 8, 2024.</li>
                <li>A participant can win only once. If a person wins more than once, he/she gets the bigger prize.</li>
                <li>Winners must present the following upon claiming of prizes:</li>
                <ul>
                  <li><b>1 valid ID</b></li>
                  <li><b>Sales invoice/Official receipt</b></li>
                  <li><b>Printed email notification from Cosmos Bazar, Inc.</b></li>
                </ul>
                <li>Winners can claim their prize at:</li>
                <ul><li>Cosmos Bazar Inc. 571 Quintin Paredes St. Binondo, Manila City</li>
                  <li>Provincial Area - Will be announced on Pilot Pens Philippines Facebook and Instagram 
pages.</li>
                </ul>
                <li>Prizes not claimed within sixty (60) days from notification will be forfeited with prior
                  DTI approval.</li>
                <li>The winner will shoulder travel documents, taxes and other travel related expenses.</li>
                <li>The trip prize is valid within six (6) months for international destination from date of claiming. Travel
                  period is 6 months after claiming period, except travel dates from December 16, 2024 to January 6,
                  2025. Travel must be completed by May 31, 2025. Black-out dates are from December 16, 2024 to
                  January 6, 2025.</li>
                <li>All employees of COSMOS BAZAR, Inc. and participating stores including their relatives 
                    up to second degree of consanguinity or affinity are not qualified to join the promo. </li>                
              </ol>          
              <p><b>DTI Fair Trade Permit No. FTEB-195053 Series of 2024</b></p>
          
          </div>
          <div className="col-xs-1 col-sm-1 col-md-2"></div>
</Row>
</Container>
</section>
    // </Layout>)
    );
    
});

export default Mechanics;