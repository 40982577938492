import { Container, Row } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <>     
      <Container fluid className="mechanics-bg" style={{background:"white" , backgroundImage:`url(/assets/86-97.jpg)`, backgroundRepeat: 'repeat', paddingTop:"50px"}}>
        <Row style={{paddingLeft:"10px",paddingRight:"10px"}}>
          <div className="col-xs-1 col-sm-1 col-md-1"></div>
          <div className="col-xs-10 col-sm-10 col-md-10 center"  style={{borderRadius:"10px", background:"white", marginBottom:"50px"}}>
            <h1>Gtec To Japan Raffle Contest Privacy Policy</h1>
            <p>This privacy policy sets out Gtec To Japan Raffle Contest uses and protects any information
                that you give Pilot Pens when you use this website. Gtec To Japan Raffle Contest is committed to ensuring that your privacy is protected. Should
                we ask you to provide certain information by which you can be identified when using this
                contest site, then you can be assured that it will only be used in accordance with this privacy statement.</p>
            <h2>What we do with the information we gather</h2>
            We require this information for:
            <ul>
            <li>Processing of personal data for raffle winner selection and verification.</li>
            <li>We may periodically send promotional emails about new products, special offers or
            other information which we think you may find interesting using the email address
            which you have provided.</li>
            </ul>
    
            <h2>Security</h2>
            <p>We are committed to ensuring that your information is secure. To prevent unauthorised
access or disclosure, we have put in place suitable physical, electronic and managerial
procedures to safeguard and secure the information we collect online.</p>
          <h2>Data Retention</h2>
<p>Personal Data shall not be retained longer than necessary. Personal data collected will be
destroyed 6 months after selection of winners.</p>
<h2>Contacting Us</h2>
<p>
If there are any questions regarding this privacy policy, you may contact us using the
following information below:<br></br>
<b>Cosmos Bazar Inc.</b><br></br>
Cosmos Bazar Inc. 571 Quintin Paredes St. Binondo, Manila City<br></br>
Tel Nos: (632) 8243-6912 to 14
</p>
{/* Email address: */}

          </div>
          <div className="col-xs-1 col-sm-1 col-md-1"></div>

            </Row>
            
        
        

</Container>
        </>
    );
}

export default PrivacyPolicy;